.Top {

}
.PanelRatingPlace {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PanelRatingPlace {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: -3px;
  right: -3px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000;
  box-shadow: inset 0 0 0 1px #00000014;
}