$width: 94px;
.MoneyButton {
  width: $width;
  z-index: 0!important;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    outline: none;
    width: 90px;
    height: 86px;
    position: fixed;
    bottom: 4px;
    padding: 2px;
    z-index: 0!important;
    border-radius: 50%;
    transition: background 5000s ease-in-out;
    cursor: pointer;

    background: linear-gradient(
                    33deg
            , #646464 0%, #969696 40%, #898989 60%, #717171 100%);
    font-size: 26px;
    color: #fff;
    font-weight: 900;


    &:active {
      background: linear-gradient(
                      33deg
              , #4e4e4e 0%, #4a4949 40%, #464646 60%, #717171 100%);
    }
  }

}