.UserData {
  .AvatarWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .Avatar {
      >div {
        >div {
          border: 3px solid #f7d422;
          padding: 3px;
        }

      }
    }
  }
  .PremiumMainWrapper {
    display: flex;
    justify-content: center;
    //margin-bottom: -40px;

    .premium_wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -34px;
      right: -34px;
      //width: 40px;
      //height: 40px;
      //border-radius: 50%;
      //background: #f7a422;
      z-index: 2;

      .premium {
        color: #ffffff;
        position: absolute;

        z-index: 2;


        //padding: 10px;
      }

      .premium_popup {
        position: absolute;
        //left: -135px;
        top: -48px;

        width: 220px;
        padding: 5px;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        background: #ffffffd1;
        font-style: italic;
        font-size: 14px;
        text-align: center;
        color: #2f2f2f;
        z-index: 10;
      }
    }
  }


}