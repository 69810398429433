.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 8;
}
.blockCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}