.Hello {
  margin-bottom: 37px;
  box-sizing: border-box;
  white-space: normal;
  line-height: 25px;

  span {
    font-weight: normal;
    font-size: 17px;
    margin-top: 5px;
    line-height: 33px;
  }
}

.buttons {

  display: flex;

  button {
    width: 100%;
  }
}